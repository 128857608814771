<template>
  <v-layout>
    <v-main :style="layoutStyle">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isExcludedPage() {
      // Array of route names to exclude
      const excludedPages = ['login', 'signup', 'forgot-password', 'terms', 'data-protection', 'set-password']

      return excludedPages.includes(this.$route.name)
    },
    layoutStyle() {
      // Remove padding for excluded pages; apply it otherwise
      return this.isExcludedPage
        ? 'padding: 0;'
        : this.mini
          ? 'padding: 0 0 0 36px;'
          : 'padding: 0 0 0 200px;'
    }
  }
}
</script>
